import { useState, useEffect } from "react";
import "./styles.css";
import Loader from "./Loader";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
  const nameWithEquals = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(nameWithEquals) === 0) {
      return cookie.substring(nameWithEquals.length, cookie.length);
    }
  }

  return "";
}

const Chat = () => {
  const [messages, setMessages] = useState([]);

  const [newMessage, setNewMessage] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const resetSession = () => {
    const cookieName = "sessionId";
    const newSessionId = uuidv4();
    setCookie(cookieName, newSessionId, 30); // Store the session ID as a cookie for 30 days
    setSessionId(newSessionId); // Update sessionId state
  };

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const fetchConversationHistory = async () => {
    try {
      setLoadingHistory(true); // Set loadingHistory to true before fetching
      const response = await axios.get(
        `https://backend-hdnnkdvsxq-uc.a.run.app/get_conversation_history?session_id=${sessionId}`
      );
  
      const conversationHistory = response.data.conversation_history;
      if (conversationHistory) {
        const newMessages = conversationHistory.map((message, index) => ({
          id: index + 1,
          text: message.content,
          sender: message.role === "user" ? "user" : "bot",
          loading: false,
        }));
        setMessages(newMessages);
      }
    } catch (error) {
      console.error("Error fetching conversation history:", error);
    } finally {
      setLoadingHistory(false); // Set loadingHistory to false after fetching
    }
  };
  

  useEffect(() => {
    if (sessionId) {
      fetchConversationHistory();
    }
  }, [sessionId]);

  // Move the cookie handling functions and sessionId retrieval inside the component
  useEffect(() => {
    const cookieName = "sessionId";
    const existingSessionId = getCookie(cookieName);

    if (!existingSessionId) {
      const newSessionId = uuidv4();
      setCookie(cookieName, newSessionId, 30); // Store the session ID as a cookie for 30 days
      setSessionId(newSessionId); // Update sessionId state
    } else {
      setSessionId(existingSessionId); // Update sessionId state
      console.log("Session ID already exists:", existingSessionId);
    }
  }, []);

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const newId =
        messages.length > 0 ? messages[messages.length - 1].id + 1 : 1;
      const newMessages = [
        ...messages,
        { id: newId, text: newMessage, sender: "user" },
        { id: newId + 1, text: "", sender: "bot", loading: true },
      ];
      setMessages(newMessages);
      setNewMessage("");

      try {
        const response = await axios.post(
          "https://backend-hdnnkdvsxq-uc.a.run.app/process_query",
          {
            query: newMessage,
            session_id: sessionId,
            n_search: 7, // You can adjust this value based on your requirements
          }
        );

        const assistantMessage = response.data.response;
        console.log(assistantMessage);

        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message.id === newId + 1
              ? { ...message, text: assistantMessage, loading: false }
              : message
          )
        );
      } catch (error) {
        console.error("Error fetching assistant response:", error);
      }
    }
  };

  // const handleSendMessage = () => {
  //   if (newMessage.trim() !== '') {
  //     const newId = messages[messages.length - 1].id + 1;
  //     const newMessages = [
  //       ...messages,
  //       { id: newId, text: newMessage, sender: 'user' },
  //       { id: newId + 1, text: '', sender: 'bot', loading: true },
  //     ];
  //     setMessages(newMessages);
  //     setNewMessage('');
  //     setTimeout(() => {
  //       const randomReply = `This is a random reply: ${Math.random()}`;
  //       setMessages((prevMessages) =>
  //         prevMessages.map((message) =>
  //           message.id === newId + 1
  //             ? { ...message, text: randomReply, loading: false }
  //             : message
  //         )
  //       );
  //     }, 1000);
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hubermangpt.appspot.com/o/channels4_profile.jpg?alt=media&token=a596c9a0-9a9b-491f-8b5d-ca03c4d4e546"
          alt="Profile"
          className="rounded-full"
        />

        <h2>HubermanGPT</h2>
        <div className="chat-header-buttons">
          <button className="reset-button" onClick={resetSession}>
            Reset
          </button>
          <button className="info-button" onClick={toggleInfoModal}>
            Info
          </button>
        </div>
      </div>

      {showInfoModal && (
        <div className="info-modal">
          <div className="info-modal-content">
            
            <p>This was made with ❤️ by <a href="https://twitter.com/neuroemi" style={{color:'red'}}>Neuroemi</a> <br></br>
            With huge admiration to <a href="https://www.youtube.com/@hubermanlab" style={{color:'red'}}>Dr. Andrew Huberman</a></p>
            <br/>
            <p>Although informed with 96/136 videos, HubermanGPT is learning and improving everyday!</p>
            <br/>
            <p>This app is based on the ChatGPT language model, and although it's trained with direct data from the podcast, please consider that it's still prone to hallucination.</p>
            <br/>
            <p>The information HubermanGPT presents is not medical advice, please refer to your healthcare professional before implementing any nutritional change.</p>
            <br></br>
            <button onClick={toggleInfoModal} className='button closeButton' >Close</button>
          </div>
        </div>
      )}

      <div className="chat-messages" onKeyDown={handleKeyDown}>
        {loadingHistory && <Loader />}

        {messages.map((message, index) => (
          <>
            {message.text.split("\n").map((line, lineIndex) => (
              <>
                {line.length > 0 && (
                  <div
                    key={message.id}
                    className={`chat-message ${message.sender} ${
                      message.sender === "user"
                        ? "justify-end"
                        : "justify-start"
                    } ${index === messages.length - 1 ? "mb-4" : "mb-2"}`}
                  >
                    {line}
                  </div>
                )}
                {message.loading && message.sender === "bot" && (
                  <div className="chat-message bot">
                    <Loader />
                  </div>
                )}
              </>
            ))}
          </>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={handleNewMessageChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default Chat;
