import './loader.css'

const Loader = () => (
  <div className="loader">
    <div className="loader-dot1"></div>
    <div className="loader-dot2"></div>
    <div className="loader-dot3"></div>
  </div>
);

export default Loader